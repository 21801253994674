@use "../variables";

.navbar {
  width: 100%;

  // Search background
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue;
    transform: scaleX(0);
    transform-origin: right center;
    transition: all 300ms $transition-ease;
    z-index: -1;
  }

  // Slide background from right when search is visible
  #{$SEARCH_VISIBLE_CLASS} & {
      &::after {
          transform: scaleX(1);
      }
  }

  @include desktop {
    min-height: $navbar-height-large;

    // On desktop, search background should overlap navbar menu items
    &::after {
      z-index: 1;
    }
  }
}

.navbar-item {
  font-weight: $weight-bold;
  color: $white;

  @include touch {
    &:hover,
    &:focus {
      color: $white !important; // dirty override of Bulma a.navbar-item
    }

    .navbar-dropdown & {
      font-size: $font-size-desktop;
      font-weight: $weight-normal;
      padding: $spacing-xs;
    }
  }

  // Change text color when search is visible on desktop
  @include desktop {
    color: $text;
    padding-left: $navbar-item-padding;
    padding-right: $navbar-item-padding;
  }
}

.navbar-link {
  color: $white;

  @include touch {
    &:hover,
    &:focus {
      color: $white;
    }
  }

  // Change text color when search is visible on desktop
  @include desktop {
    color: $text;
  }
}

.navbar-dropdown {
  @include touch {
    padding: 0;
  }
}

.navbar-menu {
  @include touch {
    background: $blue;
    text-align: center;
    padding: $spacing * 1.5 0;
  }

  // Prevent overlap of search icon
  @include desktop {
    margin-right: $navbar-height-large + $navbar-item-padding;
  }
}

// Extra class for making sure that the extra <div> added during React mounting will be positioned correctly
.navbar-menu-toggle {
  z-index: 1;
  margin-left: auto;
}


.navbar-burger {
  @include touch {
    color: $white;
    background: $blue;

    &:hover,
    &:focus {
      color: $white;
      background: $blue;
    }
  }

  // Hamburger bar
  span {
    height: 2px;
  }
}

.navbar-close {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}