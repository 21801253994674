form {
  display: inherit;
}

.input {
  height: $control-height;

  .table & {
    border: 2px solid $grey;
  }
}

.textarea {
  .table & {
    border: 2px solid $grey;
  }
}

.checkbox {
  display: none;

  & + label {
    display: block;
    position: relative;
    padding-left: $spacing * 1.5;
    margin-bottom: $spacing;
    cursor: pointer;

    // Checkbox
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: $spacing;
      height: $spacing;
      margin-top: -1px;
      transform: translate3d(0, -50%, 0);
      border: 2px solid $black;
    }
  }

  &:hover + label,
  &:focus + label,
  &:checked + label {
    color: $blue;

    &::before {
      border-color: $blue;
    }
  }

  &:checked + label {
    &::before {
      background: $blue;
    }
  }
}

.field_with_errors {
  input,
  select {
    color: $danger !important;
    border: 1px solid $danger !important;
    background-color: $danger-light !important;

    &::placeholder {
      color: $danger !important;
    }
  }
}