@use "variables";

.capitalize-first-letter {
  &::first-letter {
    text-transform: capitalize;
  }
}

.no-padding-touch {
  @include touch {
    padding: 0 !important;
  }
}