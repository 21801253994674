@mixin link-color($color) {
  a {
    color: $color;

    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: $color;
    }
  }
}

@mixin dots($dots-width: 250, $dots-number: 16) {
  position: absolute;
  content: '';
  width: #{$dots-width}px;
  height: 100%;
  right: #{round($dots-width / ($dots-number / 2) * -1)}px;
  bottom: #{round($dots-width / ($dots-number / 2) * -1)}px;
  background-image: radial-gradient($blue 10%, transparent 20%);
  background-repeat: repeat;
  background-position: bottom right;
  background-size: #{round($dots-width / $dots-number)}px
                  #{round($dots-width / $dots-number)}px;
  z-index: -1;
  overflow: visible;

  @include mobile {
    display: none;
  }
}

@mixin input-primary {
  color: $white;
  background-color: $primary;
  border-color: transparent;

  &:hover,
  &:focus {
    border-color: transparent;
  }
  
  &::placeholder {
    color: $white;
  }
}

@mixin collapsible {
  min-height: 3rem;

  // Show children when visible
  &.visible {
    color: $white;
    background: $blue;
    padding: $spacing;

    a {
      color: $white;
    }
  }

  strong {
    color: $white;
  }

  p {
    font-weight: $weight-normal;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  img {
    display: block;
    background-color: $white;
    padding: $spacing-xs;
    margin: $spacing 0;
  }
}

@mixin collapsible-toggle {
  position: relative;
  display: inline-block;
  margin: 0;
  height: $control-height;
  padding: 0 $spacing;
  padding-right: $spacing-md;
  font-weight: $weight-bold;
  color: $white;
  background-color: $blue;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    width: 8px;
    height: 12px;
    top: 50%;
    left: auto;
    right: $spacing;
    transform: translate3d(0, -50%, 0);
    background-image: url('../assets/svg/chevron-right.svg');
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
  }

  .visible & {
    display: inline-block;
    padding: 0;
    padding-right: $spacing-md;
    margin-bottom: $spacing;

    &::after {
      transform: translate3d(0, -50%, 0) rotate(90deg);
      right: $spacing;
    }
  }

  &:not(.visible &) {
    &:hover,
    &:focus {
      background-color: $lightblue;
    }
  }
}