@use "../variables";

.hero {
  position: relative;
  display: block;
  color: $white;
  padding: 0;
  margin-bottom: $spacing * 5;
  overflow: hidden;
}

.hero-left-column {
  width: 100%;
  flex: none;

  @include tablet {
    width: 66.66%;
  }

  @include desktop {
    width: 50%;
  }
}

.hero-content {
  position: relative;
  padding: $spacing * 4 0;

  @include tablet {
    padding-right: $spacing-md;
  }

  // Fluid shape right
  &::before {
    position: absolute;
    content: '';
    top: -15%;
    left: 95%;
    width: 200vw;
    height: 125%;
    background: url('../assets/svg/hero-bg-right-green.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left top;
    z-index: -1;

    @include mobile {
      display: none;
    }

    @include widescreen {
      height: 115%;
    }
  }

  // Fluid shape left
  &::after {
    position: absolute;
    content: '';
    top: -15%;
    right: -20%;
    width: 200vw;
    height: 115%;
    background: url('../assets/svg/hero-bg-left.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right top;
    z-index: -1;

    @include mobile {
      width: calc(100% + #{$spacing} * 2);
      height: 100%;
      right: -$spacing;
      bottom: 0;
      background: url('../assets/svg/hero-bg-left-mobile.svg');
      background-position: bottom center;
      background-size: cover;
      top: auto;
    }
  }
}

.hero-link {
  display: block;
  color: $lightblue;
  margin-bottom: $spacing;

  svg {
    vertical-align: middle;
    height: 100%;
    transform: rotate(-90deg);
    margin-top: -1px;
    fill: $lightblue;
  }

  h5 {
    display: inline-block;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    color: $lightblue;
  }
}

.hero-title {
  margin-bottom: $spacing;

  @include touch {
    font-size: $font-size-desktop * $factor * $factor;
    line-height: $font-size-desktop * $factor * $factor * 1.3;
  }
}

.hero-subtitle {
  color: $lightblue;
}

.hero-text {
  font-weight: $weight-bold;
  margin-bottom: $spacing * 3;
  
  @include tablet {
    margin-bottom: $spacing * 6;
    min-height: 6rem;
  }
}