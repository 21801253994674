@use "../variables";
@use "../mixins";

.article-content {
  position: relative;

  h3,
  h4 {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: -$spacing;
      left: 0;
      background: $yellow;
      width: $spacing * 4;
      height: 4px;
    }
  }

  h3,
  h4,
  p,
  ul,
  ol {
    margin-bottom: $spacing-md;
  }

  ul {
    padding-left: $spacing * 1.5;

    li {    
      position: relative;  
      padding: 0.1rem;
      padding-left: $spacing;

      // Bullet
      &::after {
        position: absolute;
        content: '';
        top: 0.75rem;
        left: 0;
        width: 5px;
        height: 5px;
        background: $blue;
        border-radius: 50%;
      }
    }
  }

  ol {
    padding-left: $spacing * 2.5;

    li {
      padding-left: $spacing-xs;
    
      &::marker {
        color: $blue;
      }
    }
  }

  a {
    color: $blue;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: $blue;
      text-decoration: none;
    }
  }

  table {
    margin: $spacing auto;

    td,
    th {
      padding: $spacing-sm;
      text-align: left;
    }
  }
}

.article-author {
  margin: $spacing-lg 0;
}

.tip-message,
.download-pdf,
.external-references-box {
  padding: $spacing-md $spacing;
  color: $white;
  background: $blue;

  @include link-color($white);

  h4,
  h5,
  strong // strong tag is being used as h5 in WYSIWYG content
  {
    color: $lightblue;

    @include link-color($lightblue);
  }

  h4,
  h5 {
    padding-bottom: $spacing-sm;
  }

  @include tablet {
    padding: $spacing * 1.5;
  }
}

.download-pdf {
  @include link-color($lightblue);

  a {
    font-weight: $weight-bold;
  }
}

.question {
  padding: $spacing;
  margin-bottom: $spacing-md;
  border: 4px solid $blue;

  h5 {
    color: $blue;

    strong {
      color: $blue;
    }

    &::after {
      display: none;
    }
  }

  p {
    margin-bottom: $spacing;
    font-weight: $weight-bold;
  }

  @include tablet {
    padding: $spacing * 1.5;
  }
}

.answer {
  margin-top: $spacing;
  @include collapsible;

  &.visible {
    padding-top: 0;
  }
}

.answer-toggle {
  @include collapsible-toggle;
}

.answer-content {
  display: none;

  .visible & {
    display: block;
  }
}