.search-form {
  display: flex;
  justify-content: center;
  margin: $spacing 0;
  
  @include mobile {
    flex-direction: column;
  }

  input,
  select {
    @include input-primary;
    height: $input-height;
    padding-left: $spacing;
    // box-shadow: 0 2px 4px rgba($black, .5);
  }

  input {
    &::placeholder {
      color: $white;
    }
  }

  .control {
    .icon {
      height: $input-height;
      color: $white !important;
    }
  }

  .field {
    @include tablet {
      margin-right: $spacing-sm;
    }
  }

  .select,
  select {
    @include mobile {
      width: 100%;
    }
  }
}