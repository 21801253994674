$blue_poem: #00c0f3;
$hover_color: blue; // TODO

// For NNV iframe
$nnv_gray: #ececec;
$nnv_blue: #00a0d8;

.poems__container {
  position: relative;
  min-height: 730px;
}

@mixin poem_medium {
  @media screen and (min-width: 550px) {
    @content
  }
}

@mixin poem_large {
  @media screen and (min-width: 1250px) {
    @content
  }
}

.pie__container {
  --size: 300;
  @include poem_medium {
    --size: 500;
  }
  @include poem_large {
    --size: 700;
  }

  //background-image: url('../assets/img/model-background.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;
  height: calc(var(--size, 100) * 1px);
  width: 100%;

  margin-bottom: 50px;

  &.pie__container_deactivated {
    opacity: 0.1;
  }
}

.pie {
  position: absolute;
  border: 2px solid black;
  color: black;

  // background: #639;
  border-radius: 100%;
  height: calc(var(--size, 200) * 1px);
  width: calc(var(--size, 200) * 1px);
  
  overflow: hidden;
  // to fix overflow for mouse-over, also apply a clip shape
  clip-path: circle();

  // align in the middle
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  &.pie__middle { 
    z-index: 7;
    //opacity: 0.8; //TEMP

    // smaller size
    height: calc(var(--size, 200) * 0.63 * 1px);
    width: calc(var(--size, 200) * 0.63 * 1px);
  
    border: 3px solid #fff;
  
    // center vertically
    top: 50%;
    x: 90;
    // rotate whole middle -45 deg
    transform: translate(0, -50%) rotate(-45deg);
    // then add those 45 again for the labels of the middle segments

    .pie__labelbox {
      // MAGIC
      // inner circle blue dot positioning
      left: 24.5%;
      bottom: 25%;

      .pie__labelbox__label {
        transform: 
          // position in the middle
          translate(-50%, -50%) 
          // rotate text horizonatally
          rotate(-45deg) rotate(calc(var(--offset, 0deg) * -1deg));

        // .pie__labelbox__label__symbol {
        //   line-height: 70px;
        // }
      }
    }
  }

  &.pie__outer {
    //opacity: 0.8;
  }
}

.pie__segment {
  //opacity: 0.5;
  height: 100%;
  position: absolute;
  transform: translate(0, -50%)
            // always apply 90deg rotation to start at 12 o' clock
            rotate(90deg) 
            // apply rotation for this segment
            rotate(calc(var(--offset, 0deg) * 1deg));
  transform-origin: center bottom; /* same as 50% 100% */
  width: 100%;
  //border-bottom: 1px solid black;
  overflow: hidden;
  
  // about these clip-paths
  // - point `50% 100%` is the middle of the circle
  // - point `0% 100%` is the tangent point on the border of the circle
  // - point `0% X%` is the leftmost point (outside the circle)
  //   (where X determines the angle))
  // for 15deg:
  &.pie__segment__30 {
    clip-path: polygon(0% 71%, 0% 100%, 50% 100%, 50% 100%)
  }
  // for 90deg
  &.pie__segment__90 {
    // a square
    clip-path: polygon(0% 50%, 50% 50%, 50% 100%, 0% 100%)
  }

  &:hover {
    cursor: pointer;
  }
}

.pie__labelbox {
  //background: blue; // TEMP

  // MAGIC
  // outer circle blue dot positioning
  left: 8%;
  bottom: 10.5%;

  pointer-events: none;
  position: absolute;
  height: 2px;
  width: 2px;
}

.pie__labelbox__label {
  //opacity: 0.8;
  text-align: center;
  font-size: 40px;
  position: absolute;
  // MAGIC: 28%
  transform: translate(-29%, -50%) rotate(-90deg) rotate(calc(var(--offset, 0) * -1deg));
  line-height: 30px;
  // rotate around the center of the label
  transform-origin: 50% 50%;    
}

.pie__labelbox__label__symbol {
  font-size: 30px;
  line-height: 30px;
  font-family: 'cmmi10';
  sub {
    font-size: 15px;
    line-height: 10px;
    transform: translate(-50%, 9px);
    position: absolute;
    top: 7px;
  }

  @include poem_medium {
    font-size: 45px;
    line-height: 40px;
    sub {
      font-size: 25px;
      top: 15px;
    }
  }

  @include poem_large {
    font-size: 75px;
    line-height: 70px;
    sub {
      font-size: 40px;
      top: 35px;
    }
  }

  // the letter g has an annoying baseline
  &.pie__labelbox__label__symbol_g {
    line-height: 35px;
    margin-top: -5px;

    @include poem_medium {
      line-height: 50px;
      margin-top: -10px;
    }

    @include poem_large {
      line-height: 86px;
      margin-top: -20px;
    }
  }
}
.pie__labelbox__label__name {
  font-size: 9px;
  width: 40px;
  line-height: 10px;
  @include poem_medium {
    font-size: 12px;
    width: 60px;
    line-height: 15px;
  }
  @include poem_large {
    font-size: 16px;
    width: 100px;
    line-height: 20px;
  }
}


// INNER

.pie_inner {
  //opacity: 0.8;
  &:hover {
    cursor: pointer;
    background-color: $hover_color;
  }

  position: absolute;
  z-index: 8;
  border-radius: 100%;
  background-color: $blue_poem;
  border: 3px solid white;

  // align vertically in the middle
  top: 50%;

  height: calc(var(--size, 100) / 3.5 * 1px);
  width: calc(var(--size, 100) / 3.5 * 1px);
  transform: translate(0, -50%);

  // align horizontally in the middle
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  text-align: center;
}

.pie__segment__inner {
  //background: var(--bg);
  background: $blue_poem; // as fallback
  // due to rotation, this is the border on the side of the segment
  border-top: 2px solid black;
  content: '';
  height: 100%;
  position: absolute;
  transform: translate(0, 100%) rotate(calc(var(--value, 0) * 1deg));
  transform-origin: 50% 0;
  width: 100%;
}

.pie__segment__inner:hover {
  background-color: $hover_color;
}

.symbol__higgs {
  font-family: 'cmmi10';
  position: absolute;
  top: 45%;
  transform: translate(0%, -50%);
  width: 100%;
  
  font-size: 50px;
  @include poem_medium {
    font-size: 75px;
  }
  @include poem_large {
    font-size: 96px;
  }
}

.label__higgs {
  position: absolute;
  top: 63%;
  width: 100%;

  font-size: 9px;
  @include poem_medium {
    font-size: 12px;
  }
  @include poem_large {
    font-size: 20px;
  }
}


// DETAILS

.particle_details {
  // position: absolute;
  top: 0;
  padding: 20px;

  z-index: 9;
  min-width: 80%;  

  background: $blue;
  color: white;

  // Klein:
  // top: 5%;
  // left: 50%;
  // transform: translate(-50%, 0%);
  // border-radius: 10px;

  // Groot:
  // top: 0%;
  // left: 50%;
  // transform: translate(-50%, 0%);
  min-width: 80%;
  width: 100%;
  min-height: 100%;
  border-radius: 0;
  margin-bottom: $spacing-md;

  &.for_nnv_iframe {
    color: #000;
    background: #fff;
    font-family: 'Helvetica Neue', Helvetica, Arial, Sans-Serif;

    .particle_details__button {
      font-size: 14px;
      padding: 0 10px;
      &:not(.active) {
        background: $nnv_gray;
      }
      &.active {
        background: $blue_poem; //$nnv_blue;
      }
    }
  }
}

.particle_details__title {
  text-align: center;
  margin-bottom: $spacing;
}

.particle_details__buttons {
  text-align: center;
}

.particle_details__button {
  border: 0;
  margin-top: 10px;

  // sorry for the uggliness
  // padding-right: 10px;
  // padding-left: 10px;
  
  margin-left: 4px;
  margin-right: 4px;

  &.active {
    background: $yellow;
  }
}

.particle_details__content { 
  padding-top: $spacing;
  text-align: center;
}

.particle_details__poem_title {
  margin-bottom: $spacing-md;
}

.particle_details__back {
  color: white;
  padding-left: 16px;
  font-size: 1.2rem;
  position: relative;
  margin-bottom: 10px;

  @include poem_large {
    position: absolute;
  }

  &:hover {
    color: $lightblue;
  }
  &:hover::before {
    opacity: 0.6;
  }
  &::before {
    position: absolute;
    content: '';
    width: 8px;
    height: 12px;
    top: 50%;
    left: 0;
    right: $spacing;
    transform: translate3d(0, -50%, 0);
    background-image: url('../assets/svg/chevron-left.svg');
    background-size: cover;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
  }

  &.for_nnv_iframe {
    // TODO
    color: black;
    background: $nnv_gray;
    padding-left: 25px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;

    &::before {
      left: 10px;
      background-image: url('../assets/svg/chevron-left-black.svg');
    }
  }
}