.articles-container {
  flex-grow: 1;
}

.articles-title {
  margin-top: $spacing-md;
  margin-bottom: $spacing;

  @include tablet {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-md;
    text-align: center;
  }
}

.articles-subtitle {
  margin: $spacing-md auto;
  
  @include tablet {
    max-width: 80%;
    text-align: center;
  }
}

.articles-intro {
  margin-bottom: $spacing-md;

  @include tablet {
    margin: $spacing * 3 auto;
    margin-top: -$spacing-sm;
    width: 70%;
    text-align: center;
  }

  p {
    margin-bottom: $spacing-md;
  }
  
  a:not(.button) {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.articles-no-results {
  margin: $spacing-md auto;
    
  @include tablet {
    max-width: 80%;
    text-align: center;
  }
}

.articles-columns {
  @include tablet {
    .column {
      display: flex;
    }
  }
}