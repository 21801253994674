@import 'bulma/bulma.sass';

// Remove strange vertical spacing on Bulma columns causing overlaps between components
// See issue https://github.com/jgthms/bulma/issues/766
.columns {
  margin-top: 0;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.column {
  padding-top: 0;
  padding-bottom: 0;
}