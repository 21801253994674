.table {
  border-collapse: separate; 
  border-spacing: 0 $spacing-sm;

  tr {
    margin-bottom: $spacing-xs;
    background-color: $white;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  th {
    label {
      display: inline-block;
      padding-top: $spacing-sm;
    }
  }
  td {
    vertical-align: middle;
  
    &:not(:first-child) {
      font-size: $font-size-touch;
    }

    @include mobile {
      padding: $spacing-sm;

      &:first-child {
        display: block;
        flex-basis: 100%;
      }
    }
  }

  svg,
  path {
    vertical-align: middle;
    opacity: .6;
  }

  .content & {
    th {
      // vertical-align: middle;
    }
  }
}