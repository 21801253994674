@use "../variables";

.socials-link {
    position: relative;
    display: inline-flex;

    &:first-of-type {
        margin-right: $spacing-xs;
    }

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $yellow;
        transform-origin: center bottom;
        transform: scaleY(0);
        transition: transform $transition-duration $transition-ease;
        z-index: 0;
    }

    svg,
    path {
        fill: $yellow;
        z-index: 1;
        transition: fill $transition-duration * 1.5 $transition-ease;
    }

    &:hover,
    &:focus {
        svg,
        path {
            fill: $blue;
        }
    
        &::after {
            transform: scaleY(1);
        }
    }
}