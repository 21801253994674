.image-with-dots-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $spacing-lg;
}

.image-with-dots {
  p {
    margin: 0;
    padding: $spacing;
  }

  figure {
    position: relative;
    display: table;
    border-spacing: 0;
    margin: 0;
    max-width: 100%;
    text-align: center;

    img {
      vertical-align: top;
    }
  }
  
  figcaption {
    display: table-caption;
    caption-side: bottom;
    padding: $spacing;
    font-size: $font-size-touch;
    min-width: 10rem;
    text-align: left;
  }
}

.dots {
  @include dots;
}