$triangle: 1.2rem;

.question-balloons {
}

.question-balloon-title {
  font-size: $font-size-touch;
}

.question-balloon {
  position: relative;
  padding: $spacing;
  background-color: $white;
  margin-bottom: $spacing-md;

  &::after {
    position: absolute;
    content: '';
    width: $triangle;
    height: $triangle;
    bottom: calc((#{$triangle} - 1px) * -1);
    left: calc(#{$triangle} + #{$spacing-xs});
    border-top: $triangle solid $white;
    border-right: $triangle solid transparent;
  }

  @include tablet {
    margin-bottom: $triangle;
    width: calc((5/6) * 100%);

    &.is-reply {
      margin-left: calc(100% / 6);
    }
  }

  &.is-reply {
    background-color: $purple;

    &::after {
      left: auto;
      right: calc(#{$triangle} - #{$spacing-xs});
      border-top-color: $purple;
      border-left: $triangle solid transparent;
      border-right: 0;
    }
  }
}