.video {
  position: relative;
  margin-bottom: $spacing-lg;

  &::before {
    position: absolute;
    content: '';
    top: $spacing-sm;
    left: -$spacing-sm;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.source {
  @include mobile {
    display: none;
  }
}