.search-select {
  &.rc-select {
    width: auto;
    font-size: inherit;

    input {
      cursor: pointer;
    }

    // Override default styling specificity
    &.rc-select-single:not(.rc-select-customize-input) {
      .rc-select-selector {
        height: $input-height;
        padding-left: $spacing;
        box-shadow: 0;
        color: $white;
        background: $blue;
        border: 0;
      }

      // Selected option text
      .rc-select-selection-placeholder,
      .rc-select-selection-item {
        top: 50%;
        transform: translate3d(0, -50%, 0);
        opacity: 1;
        left: $spacing;
      }
    }
  }
}

.select-dropdown {
  &.rc-select-dropdown {
    z-index: 99;
    border: 0;
    
    .rc-virtual-list {
      margin-top: -4px;
      background-color: $blue;
    }

    .rc-virtual-list-holder-inner {
      padding-top: $spacing-sm;
    }

    .rc-select-item,
    .rc-select-item-group {
      color: $white;
      font-size: 100%;
    }

    .rc-select-item-group {
      padding-top: $spacing-sm;
    }

    .rc-select-item-option:hover,
    .rc-select-item-option:focus,
    .rc-select-item-option-active {
      cursor: pointer;
      background-color: $lightblue;
    }
  }
}