@use '../variables';
@use '../mixins';

.collapsed {
  @include collapsible;
  background-color: $blue;
  padding: $spacing * 1.5;
  margin: $spacing-md 0;
  cursor: pointer;

  &:not(.visible) {
    > *:not(h5) {
      display: none;
    }
  }

  h5 {
    @include collapsible-toggle;
    padding: 0;
    display: block;
    height: auto;

    &::after {
      right: 0;
    }
  }

  &.visible {
    padding: $spacing * 1.5;

    p {
      color: $white;
    }

    ul {
      padding: 0;

      li {
        &::after {
          background-color: $white;
        }
      }
    }

    ol {
      padding-left: $spacing;

      li {
        padding: 0;
  
        &::marker {
          color: $white;
        }
      }
    }

    h5 {
      margin-bottom: $spacing;
      
      &::after {
        transform: rotate(90deg);
      }
    }

    table {
      margin: 0;
    }
  }
}
