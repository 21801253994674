@use "../variables";

.partner-sites-wrapper {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    padding: $spacing 0;

    @include tablet {
        flex-direction: row;
        align-items: center;
    }
}

.partner-sites-title {
    font-weight: $weight-bold;
    
    @include touch {
        font-size: 1.14rem;
        margin-bottom: $spacing;
    }

    @include tablet {
        flex-basis: 25%;
    }
}

.partner-sites-links {
    display: flex;
    justify-content: space-between;
}

.partner-sites-link {
    display: flex;
    align-items: center;
    padding: 0 $spacing-sm;
    margin-right: $spacing;

    @include tablet {
        margin-right: 3rem;
    }
}

