.related-articles {
  position: relative;
  padding: $spacing-lg 0;

  &::before {
    position: absolute;
    content: '';
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    background-size: cover;
    z-index: -1;
  }
}