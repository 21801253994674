@use '../mixins';

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;

  @include touch {
    margin-top: $navbar-height;
  }
}

.container {
  @include touch {
    padding-left: $spacing;
    padding-right: $spacing;
  }
}