@mixin page-color($name, $color) {
  &.articles-container {
    background-color: $color;
  }

  .hero-content {
    &::before {
      background-image: url('../assets/svg/hero-bg-right-#{$name}.svg');
    }
  }

  .article-image-background {
    background-color: $color;
  }

  .related-articles {
    &::before {
      background-image: url('../assets/svg/bg-#{$name}.svg');
    }
  }

  .image-with-dots-container {
    figcaption {
      background-color: $color;
    }
  }

  .video {
    &::before {
      background-color: $color;
    }
  }
}

.aqua {
  @include page-color('aqua', $aqua);
}

.green {
  @include page-color('green', $green);
}

.purple {
  @include page-color('purple', $purple);
}

.grey {
  @include page-color('grey', $grey);
}