@font-face {
  font-family: 'CeraPro';
  src: url('../assets/fonts/cerapro-regular.woff2') format('woff2'),
       url('../assets/fonts/cerapro-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'CeraPro';
  src: url('../assets/fonts/cerapro-bold.woff2') format('woff2'),
       url('../assets/fonts/cerapro-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'cmmi10';
  src: url('../assets/fonts/cmmi10.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}
