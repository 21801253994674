.jumbotron {
  color: $white;
  position: relative;
  text-align: center;
  padding: $spacing-xl $spacing;
  margin-bottom: -$spacing;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/svg/bg-blue.svg');
    background-size: cover;
    transform-origin: center center;
    transform: rotate(180deg);
  }

  p {    
    @include tablet {
      margin: 0 auto;
      max-width: 75%;
      font-size: 19px;
    }
  }
}


.logo {
  width: 13rem;
  height: 3.2rem;
}