.home-questions {
  background: $grey;
  overflow: hidden;
}

.home-questions-content {
  position: relative;
  z-index: 1;
  padding-right: $spacing-md;

  &::after {
    position: absolute;
    content: '';
    width: 100vw;
    height: calc(100% + 8rem);
    bottom: -2rem;
    right: 0;
    background-image: url('../assets/svg/home-question-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right bottom;
    z-index: -1;

    @include mobile {
      width: 500%;
      right: -85%;
      bottom: 0;
      background-position: bottom right;
      background-size: cover;
    }

    @include desktop {
      bottom: -8rem;
    }

    @include fullhd {
      width: 80vw;
    }

  }

  @include desktop {
    padding-right: $spacing-xl;
  }
}