@use "../variables";

.header {
    display: flex;
    width: 100%;
    background-color: $white;
    z-index: 99;
    box-shadow: 0 2px 5px rgba($blue, .07);

    // Mobile navbar menu background
    &#{$NAVBAR_MENU_VISIBLE_CLASS} {
        background-color: $blue;
    }

    @include touch {
        position: fixed;
    }
}

#logo {
    svg {
        width: 7.65rem;
        max-height: 1.9rem;

        @include desktop {
            width: 10.3rem;
            max-height: none;
        }
    }

    svg,
    path {
        fill: $blue;
        transition: fill $transition-duration;

        #{$SEARCH_VISIBLE_CLASS} &,
        #{$NAVBAR_MENU_VISIBLE_CLASS} & {
            fill: $white;
        }
    }

    #{$SEARCH_VISIBLE_CLASS} & {
        // Hide logo on touch devices if search is visible
        @include touch {
            pointer-events: none;

            svg,
            path {
                fill: transparent;
            }
        }
        
        // Prevent search background overlapping
        z-index: 2;
    }

    @include tablet {
        padding-left: $spacing * 1.5;
    }
}

.header-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    right: $navbar-height;
    padding-left: $spacing-sm;

    @include mobile {
        width: 10rem;

        #{$SEARCH_VISIBLE_CLASS} & {
            right: 0;
            padding-right: $navbar-height;
            width: 100%;
        }
    }

    @include desktop {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        right: 0;
        z-index: -1; // Prevent search background overlapping

        #{$SEARCH_VISIBLE_CLASS} & {
            z-index: 2;
        }
    }
}

.header-search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $navbar-height;
    height: $navbar-height;
    flex-shrink: 0;

    svg,
    path {
        fill: $blue;

        #{$SEARCH_VISIBLE_CLASS} &,
        #{$NAVBAR_MENU_VISIBLE_CLASS} & {
            fill: $white;
        }
    }

    @include desktop {
        background: $blue;
        width: $navbar-height-large;
        height: $navbar-height-large;

        svg,
        path {
            fill: $white;
        }
    }
}

.search-icon {
    width: 19px;
    height: 19px;
    background-color: white;
    -webkit-mask-image: url('../assets/svg/search.svg');
    mask-image: url('../assets/svg/search.svg');
}

.close-icon {
    width: 16px;
    height: 16px;
    background-color: white;
    -webkit-mask-image: url('../assets/svg/close.svg');
    mask-image: url('../assets/svg/close.svg');
}

.header-search-input {
    @include input-primary;
    width: 100%;
    height: 1.5rem;
    border: 0;
    border-bottom: 1px solid $white;
    transform-origin: right center;
    transform: scaleX(0);
    transition: all $transition-duration $transition-ease;
    

    &:hover,
    &:focus {
        padding: 0;
        box-shadow: none; // Override Bulma
        border: 0;
        border-bottom: 1px solid $white;
    }
    
    #{$SEARCH_VISIBLE_CLASS} & {
        transform: scaleX(1);
    }

    @include desktop {
        width: 17rem;
        height: 2rem;
    }
}