@use "../variables";

.button {
  height: 50px;

  svg,
  path {
    fill: currentColor;
  }

  svg {
    margin-left: $spacing * 1.5;
    transition: transform $transition-duration $transition-ease;
  }

  &:hover,
  &:focus {
    svg {
      transform: translate3d(0.2rem, 0, 0);
    }
  }

  &.is-white {
    &:hover,
    &:focus {
      background-color: $lightblue;
    }
  }

  &.is-small {
    border-radius: $control-radius; //Bulma fix
  }
}