@use '../variables';

.question-reply-form {
  margin-bottom: $spacing-lg;

  input {
    color: $black;
    background: $white;
    border: 0;

    &::placeholder {
      color: $black;
    }
  }

  .field {
    display: flex;
    margin-bottom: $spacing;

    @include mobile {
      flex-direction: column;
    }
  }

  .control {
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: $spacing-sm;

    &.inline {
      &:last-of-type {
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: auto;
        margin-right: 0;
      }

      @include tablet {
        margin-right: $spacing-sm;
      }
    }
  }
}

.robot-question-answer {
  display: flex;
  
  input {
    color: $white;
    width: 2rem;
    height: 1.5rem;
    margin: 0 $spacing-sm;
    padding: 0;
    border: 0;
    background: none;
    border-bottom: 2px solid $lightblue;
    

    &:hover,
    &:focus {
      border: 0;
      border-bottom: 2px solid $lightblue;
      box-shadow: none;
    }
  }
}