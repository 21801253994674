.question-nav {
    svg,
    path {
        fill: $blue;
    }
}

// index page
.question-title {
    a {
        color: $blue;

        .question-row:hover &,
        .question-row:focus & {
            text-decoration: underline;
        }
    }
}

.question-row {
    td:not(:first-child) {
        color: $lightgrey;
        font-size: $font-size-desktop * 0.75;
    }

    @include mobile {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding: $spacing-sm;
    }

    @include touch {
        td {
            &:nth-child(2) {
                width: 4rem;
            }
            &:nth-child(3) {
                width: 5rem;
            }
        }
    }
}

// Show (+ reply) question page

.question-reply {
    position: relative;
    padding-bottom: $spacing * 3; // Gives space for quote-link
}

.quote-link {
    position: absolute;
    bottom: 0;
    right: 0;

    svg {
        margin-left: $spacing-sm;
    }
}

.reply-content {
    word-break: break-word;

    ul {
        padding: $spacing-sm 25px;
        list-style-type: disc;
        li {
            padding: .1rem;
        }
    }

    ol {
        padding: $spacing-sm 30px;
        list-style-type: decimal;
        li {
            padding: .1rem;
        }
    }

    ol,
    ul {
        li::marker {
           color: $blue;
        }
    }    

    h3,
    h4,
    p {
        margin: $spacing 0;
    }

    a {
        color: $blue;

        .question-row:hover &,
        .question-row:focus & {
            text-decoration: underline;
        }
    }

    strong {
        /*
        Revert `color: $strong-color;` from generic.sass.
        To fix TinyMCE colors on strong text.
        */
        color: revert;
    }

    table {
        margin: $spacing auto;

        td,
        th {
        padding: $spacing-sm;
        text-align: left;
        }
    }
}

.reply-attachments {
    a {
        color: $blue;
    }
}

.only-for-moderators {
    border: 3px dotted;
}

table tr.only-for-moderators-row {
    background-color: #e5e5e5;
}

.responding-notice {
    color: $red;
}

.quote-container,
blockquote {
    margin: $spacing-md 0;
    padding: $spacing-sm;
    background-color: $grey;

    @include tablet {
        width: 80%;
        padding: $spacing;
        margin: $spacing-md auto;
    }

    // Ugly fix for quote title
    p:first-of-type {
        margin-bottom: $spacing-sm;
    }
}

// Fix for formula editor
#EqnEditor .panel {
    margin-bottom: $spacing-sm;
}
