@use '../variables';

.consent-footer {
  position: absolute;
  padding: $spacing-md 0;
  top: $navbar-height;
  width: 100%;
  color: $white;
  background: $black;
  z-index: 10;
  font-size: $font-size-desktop;

  @include tablet {
    position: fixed;
    z-index: 99;
    top: auto;
    bottom: 0;
  }
}

.consent-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: $spacing-md $spacing;
  color: $white;
  background: $blue;
  font-size: $font-size-desktop * 0.75;
  text-align: center;
  
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    margin-bottom: $spacing-sm;
    width: 90%;
    max-width: 90%;

    .button {
      display: block;
      margin: $spacing auto;
    }
  }
}

// Placeholder content for cards on touch devices
.touch-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 90%;
  font-size: 0.7rem;
  
  svg {
    display: block;
    margin: 0 auto;
    fill: $white;
    width: $spacing;
    height: $spacing;
  }

  span {
    display: block;
    margin: $spacing-xs auto;
  }

  button {
    text-decoration: underline;
  }

  &.with-label {
    top: calc(50% - 1rem); // make space for label on small screen

    @include touch {
      svg {
        display: none;
      }
    }

    @include tablet {
      top: 50%;
    }
  }

  @include desktop {
    display: none;
  }
}