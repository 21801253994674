.pagination {
  justify-content: center;
}

// overwrite bulma default list style
.content .pagination {
  list-style-type: none;
}

.pagination-link,
.pagination-previous,
.pagination-next {
  border: 0;
  border-radius: 0;
  background-color: $pagination-background-color; // Variable does not exist in Bulma

  &:hover,
  &:focus {
    background-color: $pagination-hover-background-color; // Variable does not exist in Bulma
  }

  // Chevrons
  svg,
  path {
    fill: $white;
  }
}

.pagination-list {
  flex-grow: 0;

  @include touch {
    li {
      flex-grow: 0;
    }
  }
}

.pagination-link {
  @include touch {
    min-width: 45px;
    height: 45px;
  }
}