$NAVBAR_MENU_VISIBLE_CLASS: ".navbar-menu-is-visible";
$SEARCH_VISIBLE_CLASS: ".search-is-visible";

// These variables are shared by Bulma and custom components

// Colors
$aqua: #9EF5FF;
$black: #201D1D;
$white: #FEFFFE;
$grey: #F0F0F0;
$lightgrey: #8F8F8F;
$blue: #2223DB;
$lightblue: #B4B5FB;
$purple: #D7BBFF;
$yellow: #FFFF00;
$green: #57E9B5;

$primary: $blue;
$link: $black;
$info: $lightblue;
$text: $black;

// Typography
$font-family: "CeraPro", sans-serif;
$font-size-touch: 17px;
$font-size-desktop: 17px;
$font-size-widescreen: 19px;

$line-height: 1.5;
$factor: 1.25; // major third scale, used for responsive ont scaling
$size-6: 1.0rem;
$size-small: 0.875rem;
$family-sans-serif: $font-family;

$container-max-width: 1110px;
$widescreen-enabled: true;
$fullhd-enabled: true;

// Spacing
$spacing: 1rem;
$spacing-xs: $spacing * 0.25;
$spacing-sm: $spacing * 0.5;
$spacing-md: $spacing * 2;
$spacing-lg: $spacing * 4;
$spacing-xl: $spacing * 6;

// Transitions
$transition-duration: 300ms;
$transition-ease: ease-out;

// Components
$body-family: $family-sans-serif;
$body-size: $size-6;
$body-color: $black;
$body-background-color: $white;

$box-radius: 0;
$box-shadow: none;
$box-padding: 2rem;

$control-height: 50px;
$control-radius: 0;
$control-border-width: 2px;

$input-height: $control-height; //custom variable
$input-color: $black;
$input-background-color: $white;
// $input-icon-color: $white;
// $input-icon-active-color: $white;
$input-border-color: transparent;
$input-hover-color: $white;
$input-hover-border-color: transparent;
$input-focus-border-color: $blue;
$input-radius: 0;
$input-shadow: none;
$input-arrow: $white;

$message-radius: 0;
$message-header-radius: 0;
$message-body-radius: 0;

$navbar-height: 2.95rem;
$navbar-background-color: transparent;
$navbar-item-hover-background-color: transparent;
$navbar-dropdown-border-top: none;
$navbar-dropdown-radius: 0;

$pagination-color: $white;
$pagination-border-color: transparent;
$pagination-margin: $spacing-md;
$pagination-hover-color: $white;
$pagination-background-color: $blue; // Custom variable
$pagination-hover-background-color: $lightblue; //Custom variable
$pagination-current-color: $black;
$pagination-current-background-color: $white;
$pagination-ellipsis-color: $black;

$table-background-color: transparent;
$table-cell-border: none;
$table-cell-padding: $spacing;

// Other variables (not Bulma, but also used in Bulma components)
$navbar-item-padding: 0.75rem;
$navbar-height-large: 4.1rem;