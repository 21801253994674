$arrow-size: 5rem;

.arrows-container {

  @include tablet {
    position: absolute;
    width: 100vw;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  @include fullhd {
    position: static;
    transform: none;
  }
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  background-color: $blue;
  transition: background-color $transition-duration $transition-ease;
  width: $arrow-size;
  height: $arrow-size;
  z-index: 1;

  // Icon
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25%;
    opacity: 1;
    z-index: 1;
  }

  &:hover {
    background-color: $purple;
  }

  @include mobile {
    display: none;
  }
}

.arrow-prev {
  left: 0;

  &::before {
    background-image: url('../assets/svg/chevron-left.svg');
  }

  @include fullhd {
    left: ($arrow-size + $spacing-md) * -1;
  }
}

.arrow-next {
  right: 0;

  &::before {
    background-image: url('../assets/svg/chevron-right.svg');
  }

  @include fullhd {
    right: ($arrow-size + $spacing-md) * -1;
  }
}

.slick-initialized {
  .slick-list {
    margin: 0 (-$spacing);
  }

  .slick-slide {
    display: block;
    padding: $spacing;
  }
}