.card {
  display: flex;
  width: 100%;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: $spacing;
  z-index: 1;
  overflow: visible;
  box-shadow: -6px 7px 0 0 $blue,
              0 2px 5px rgba($blue, .14);

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    bottom: -$spacing-xs * 1.5;
    left: -$spacing-xs * 1.5;
    background-color: $blue;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @include mobile {
    height: $spacing * 7;

    h4 {
      font-size: $font-size-desktop;
    }
  }

  @include tablet {
    flex-direction: column;
    margin-bottom: $spacing-md;
  }
  
  @include touch {
    p {
      display: none;
    }
  }

  &:hover,
  &:focus {
    // Animated background stripes
    &::after {
      background-image: linear-gradient(
        45deg,
        $lightblue 25%,
        transparent 25%,
        transparent 50%,
        $lightblue 50%,
        $lightblue 75%,
        transparent 75%,
        transparent
    );
      background-size: 50px 50px;
      background-repeat: repeat;
      animation: animated-stripes 2s linear infinite;
    }
  }
}

.card-image {
  overflow: hidden;

  .image {
    flex-grow: 1;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    @include tablet {
      padding-bottom: 66.66%;
    }
  }

  img {
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%;
    transition: transform $transition-duration $transition-ease;

    .card:hover &,
    .card:focus & {
      transform: scale(1.05);
    }
  }

  // Movies
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    // Prevent video from playing inline on touch device (see video.js)
    @include touch {
      pointer-events: none;
    }
  }

  @include touch {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 30%;
    flex-shrink: 0;
  }

  @include mobile {
    // min-height: $spacing * 6;
  }

  @include tablet {
    width: 100%;
  }
}

.card-content {
  background-color: $white;
  padding: $spacing;
  flex-grow: 1;

  h4 {
    margin-bottom: 0.9rem;
  }

  @include tablet {
    padding: $spacing * 1.5 $spacing;
    min-height: 15rem;
  }
}

.card-label {
  position: absolute;
  background: $yellow;
  text-align: center;
  padding: $spacing-xs 0;

  @include touch {
    bottom: 0;
    width: 100%;
    
    h5 {
      font-size: 0.9rem;
    }
  }

  @include tablet {
    top: 0;
    right: 0;
    bottom: auto;
    width: auto;
    padding: $spacing-xs $spacing;
  }
}

@keyframes animated-stripes {
  from {
    background-position: -50px 0;
  }
  to { 
    background-position: 0 0;
  }
}