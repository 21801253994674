@use '../variables';

body,
p {
  line-height: $line-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $weight-bold;
  word-break: break-word;
  hyphens: manual;
}

@mixin fluid-type($start-size) {
  $h6: $start-size * 0.88;
  $h5: $start-size;
  $h4: $h5 * $factor;
  $h3: $h4 * $factor;
  $h2: $h3 * $factor;
  $h1: $h2 * $factor;

  h1,
  .h1 {
    font-size: $h1;
    line-height: $h1 * 1.3;
  }

  h2,
  .h2 {
    font-size: $h2;
    line-height: $h2 * 1.3;
  }

  h3,
  .h3 {
    font-size: $h3;
    line-height: $h3 * 1.3;
  }

  h4,
  .h4 {
    font-size: $h4;
    line-height: $h4 * 1.3;
  }

  h5,
  .h5 {
    font-size: $h5;
    line-height: $h5 * 1.5;
  }

  h6,
  .h6 {
    font-size: $h6;
    line-height: $h6 * 1.5;
  }
}

// All font (and component) sizes will be relative to the html font size (in rem)
html {
  font-size: $font-size-touch;

  @include desktop {
    font-size: $font-size-desktop;
  }

  @include widescreen {
    font-size: $font-size-widescreen;
  }
}

@include touch {
  @include fluid-type($font-size-touch);
}

@include desktop {
  @include fluid-type($font-size-desktop);
}

@include widescreen {
  @include fluid-type($font-size-widescreen);
}