.tags {
  display: flex;
  flex-wrap: wrap;

  @include tablet {
    justify-content: center;
  }

  svg {
    width: 12px;
    height: 12px;
  }
}