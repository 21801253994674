@use "../variables";

.footer {
    margin-top: auto;
    color: $white;
    background-color: $blue;
    padding: 3rem 0;
}

.footer-column-right {
    @include mobile {
        display: flex;
        flex-direction: column-reverse;
        margin-top: $spacing;
    }
}

.footer-list {
    @include touch {
        margin-bottom: $spacing;
    }
}

.footer-link {
    padding: $spacing-xs 0;
    position: relative;
    display: inline-block;
    font-weight: $weight-bold;
    color: $lightblue;
    transition: all $transition-duration $transition-ease;
    z-index: 1;
    
    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: -$spacing-xs;
        width: calc(100% + #{$spacing-xs} * 2);
        height: 100%;
        transform: scale(0,0);
        transform-origin: center left;
        transition: transform $transition-duration $transition-ease;
        background-color: $lightblue;
        z-index: -1;
    }

    &:hover,
    &:focus {
        color: $blue;

        &::before {
            transform: scale(1,1);
        }
    }
}

.footer-mc-form {
    display: flex;

    .input {
        color: $black;
        background-color: $white;
        border: 0;

        &::placeholder {
            color: $black;
        }

        @include tablet {
            max-width: 16rem;
        }
    }

    .button {
        border: 0;
        color: $blue;
        background-color: $yellow;
        width: $control-height;

        svg {
            margin: 0;
        }
    }
}